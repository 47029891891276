.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  h1 {
    font-size: 22px;
    color: $red;
  }
  p {
    color: $red;
  }
}
